// extracted by mini-css-extract-plugin
export var crater = "navbar-module--crater--QQwVw";
export var crater1 = "navbar-module--crater1--Jqi6R";
export var crater2 = "navbar-module--crater2--bk1QN";
export var crater3 = "navbar-module--crater3--D5Dls";
export var star = "navbar-module--star--A+LNS";
export var star1 = "navbar-module--star1--88Dqe";
export var star2 = "navbar-module--star2--isSpc";
export var star3 = "navbar-module--star3--WwTUD";
export var star4 = "navbar-module--star4--XuhSF";
export var star5 = "navbar-module--star5--pLWyR";
export var star6 = "navbar-module--star6--bXApd";
export var toggle = "navbar-module--toggle--1e+-C";
export var toggleHandler = "navbar-module--toggleHandler--vsKsE";
export var toggleInput = "navbar-module--toggleInput--EfD8S";
export var toggleWrapper = "navbar-module--toggleWrapper--Ty+r3";