import React from "react";
// import "../css/main.css"
import "@fontsource/open-sans";
import Navbar from "./Navbar";
import Sidebar from "./Sidebar";
import Footer from "./Footer";

const Layout = ({ children }) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  const unicorn = "🦄 ";

  console.log(
    `%c Hi there! Thanks for visiting my portfolio site. ${unicorn}`,
    "font-weight: 700; color: blue; font-size: 18px;"
  );

  const face = " 😎 ";

  console.log(
    `%c Have an awesome day! ${face}`,
    "font-weight: 700; color: purple; font-size: 18px;"
  );

  return (
    <>
      <Navbar toggleSidebar={toggleSidebar} />
      <Sidebar isOpen={isOpen} toggleSidebar={toggleSidebar} />
      {children}
      <Footer />
    </>
  );
};

export default Layout;
