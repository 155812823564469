// extracted by mini-css-extract-plugin
export var contactInput = "contact-module--contactInput--1iGNb";
export var content = "contact-module--content--hjlpL";
export var diagonalBox = "contact-module--diagonalBox--V5-0I";
export var fieldset = "contact-module--fieldset--qF3wd";
export var formWrapper = "contact-module--formWrapper--xtHPn";
export var message = "contact-module--message--r6aL+";
export var social = "contact-module--social--K9-pp";
export var socialLink = "contact-module--socialLink--qd+c8";
export var socialLinkDarkMode = "contact-module--socialLinkDarkMode--QUaUq";
export var socialLinkItem = "contact-module--socialLinkItem--caih8";
export var socialList = "contact-module--socialList--jUiI+";
export var titleDarkMode = "contact-module--titleDarkMode--fBL1h";