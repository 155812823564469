import * as React from "react";
import { FaCodepen, FaLinkedin, FaTwitterSquare } from "react-icons/fa";

const data = [
  {
    id: 1,
    icon: <FaCodepen className="social-icon"></FaCodepen>,
    label: "link to codepen",
    url: "https://codepen.io/Bc-coding",
  },
  {
    id: 2,
    icon: <FaLinkedin className="social-icon"></FaLinkedin>,
    label: "link to linkedin",
    url: "https://www.linkedin.com/in/belle-carrie-24857328/",
  },
  {
    id: 3,
    icon: <FaTwitterSquare className="social-icon"></FaTwitterSquare>,
    label: "link to twitter",
    url: "https://twitter.com/BCarrie5",
  },
];
const links = data.map(link => {
  return (
    <li key={link.id}>
      <a href={link.url} className="social-link" aria-label={link.label}>
        {link.icon}
      </a>
    </li>
  );
});

const socialLinks = ({ styleClass }) => {
  return (
    <ul className={`social-links ${styleClass ? styleClass : ""}`}>{links}</ul>
  );
};

export default socialLinks;
