import React from "react"

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-container">
        <p>
          <span role="img" aria-label="high voltage sign emoji">
            &#9889;
          </span>{" "}
          Designed and Coded by Belle Carrie using Gatsby.js with{" "}
          <span className="footer-love">&#9829;</span> Copyright &copy;
          {new Date().getFullYear()} All Rights Reserved{" "}
          <span role="img" aria-label="high voltage sign emoji">
            &#9889;
          </span>
        </p>
      </div>
    </footer>
  )
}

export default Footer
