import React from "react";
import { FaBars } from "react-icons/fa";
import PageLinks from "../constants/links";

import { ThemeToggler } from "gatsby-plugin-dark-mode";

import cx from "classnames";
import * as styles from "./navbar.module.css";

const Navbar = ({ toggleSidebar }) => {
  return (
    <nav className="navbar">
      <div className="nav-center">
        <div className="nav-header">
          <div className={styles.toggleWrapper}>
            <ThemeToggler>
              {({ theme, toggleTheme }) => (
                <>
                  <input
                    type="checkbox"
                    className={styles.toggleInput}
                    id="dark mode"
                    onChange={e =>
                      toggleTheme(e.target.checked ? "dark" : "light")
                    }
                    checked={theme === "dark"}
                    aria-label="dark mode checkbox"
                  />
                  <label
                    htmlFor="dark mode"
                    className={styles.toggle}
                    aria-label="dark mode"
                  >
                    {/* <----- toggle-handler  -------> */}
                    <span className={styles.toggleHandler}>
                      <span
                        className={cx(styles.crater, styles.crater1)}
                      ></span>
                      <span
                        className={cx(styles.crater, styles.crater1)}
                      ></span>
                      <span
                        className={cx(styles.crater, styles.crater1)}
                      ></span>
                    </span>
                    {/* <----- star  -------> */}
                    <span className={cx(styles.star, styles.star1)}></span>
                    <span className={cx(styles.star, styles.star2)}></span>
                    <span className={cx(styles.star, styles.star3)}></span>
                    <span className={cx(styles.star, styles.star4)}></span>
                    <span className={cx(styles.star, styles.star5)}></span>
                    <span className={cx(styles.star, styles.star6)}></span>
                  </label>
                </>
              )}
            </ThemeToggler>
          </div>
          <button
            type="button"
            className="nav-toggle-btn"
            onClick={toggleSidebar}
            aria-label="menu toggle"
          >
            <FaBars />
          </button>
        </div>
        <PageLinks styleClass="nav-links"></PageLinks>
      </div>
    </nav>
  );
};

export default Navbar;
