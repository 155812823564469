import React from "react";
import Layout from "../components/Layout";
import Contact from "../components/ContactForm";
import SEO from "../components/Seo";

const contactForm = () => {
  return (
    <Layout>
      <SEO
        title="Contact Form"
        description="Use conform form to reach out to Belle Carre"
      />
      <Contact />
    </Layout>
  );
};

export default contactForm;
