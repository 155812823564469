import React from "react";
import SocialLinks from "../constants/socialLinks";

import * as styles from "../css/contact.module.css";

const Contact = () => {
  return (
    <section className={styles.diagonalBox}>
      <div className={styles.content}>
        <div className={styles.formWrapper}>
          <h2>Get in Touch</h2>
          <p>
            If you have any questions, or some ideas for creating some projects
            together, feel free to drop me a line using the contact form below.
            I would love to hear from you!
          </p>
          <form
            action="https://formspree.io/f/xjvpzyla"
            method="POST"
            // className={styles.form}
            aria-labelledby="contact"
          >
            <fieldset className={styles.fieldset}>
              <legend id="contact">
                <p>Send me a message here!</p>
              </legend>
              <input
                className={styles.contactInput}
                type="text"
                name="name"
                placeholder="Name"
                required
              />
              <input
                className={styles.contactInput}
                type="text"
                name="_replyto"
                placeholder="Email"
                required
              />
              <textarea
                name="message"
                placeholder="Message"
                required
                className={styles.message}
              />
              <button type="submit" className="sbtn liquid-btn blue-btn btn">
                send message
              </button>
            </fieldset>
          </form>
        </div>
        <div className={styles.social}>
          <p>
            I could also be found on the following social media platforms.
            Connect with me!
          </p>
          <SocialLinks />
        </div>
      </div>
    </section>
  );
};

export default Contact;
