import React from "react";
import { Helmet } from "react-helmet";
import { graphql, useStaticQuery } from "gatsby";

const Seo = ({ title, description, image, twitterUsername }) => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          author
          description
          siteUrl
          title
          twitterUsername
          image
        }
      }
    }
  `);
  const { site } = data;
  const { siteMetadata } = site;

  return (
    <Helmet
      htmlAttributes={{ lang: "en" }}
      title={`${title} | ${siteMetadata.title}`}
    >
      <meta
        name="description"
        content={description || siteMetadata.description}
      />
      <meta name="image" content={siteMetadata.image} />
      {/* twitter cards */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:creator" content={twitterUsername} />
      <meta name="twitter:title" content={siteMetadata.title} />
      <meta name="twitter:description" content={siteMetadata.description} />
      <meta
        name="twitter:image"
        content={`${siteMetadata.siteUrl}${siteMetadata.image}`}
      />
    </Helmet>
  );
};

export default Seo;
